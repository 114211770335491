import React, { useState, useEffect } from "react"
import { loadStripe } from "@stripe/stripe-js"
import axios from "axios"
import moment from "moment"
import { FaCartShopping } from "react-icons/fa6"
import { IoIosCard } from "react-icons/io"
import { CiBaseball } from "react-icons/ci"
import { PiBoneDuotone } from "react-icons/pi"
import { daycarePassOptions } from "../../utils/constants"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import GiftCardValidator from "../GiftCardValidator"

const NO_VISITS_LEFT = "No visits left"
const PASS_EXPIRED = "Pass Expired"
const ACTIVE = "Active"

const S_P_K =
	"pk_live_51OqlUTCZA17Y7kpHSYVSvRgB9TAmcOGEaCs2KSk1rOdiVqmihr6IcHYpdS8JT15stK7vRO63BnZle3rTquLP667000mxrpN0sC"
// "pk_test_51OqlUTCZA17Y7kpHBxVoO03oc8ghQNTneFed6QRbq9hSc7cp9UppcFFYY1oL2bhA2A70PE3y0o5teqY73WTWKMcf00EpDg6dOd"

const stripePromise = loadStripe(S_P_K)

export default function Index({ clientID, data, clientLocation }) {
	const [passesView, setPassesView] = useState("my-purchased-passes")
	const [visits, setVisits] = useState([])
	const [code, setCode] = useState("")
	const [errorMsg, setErrorMsg] = useState("")
	const [validMessages, setValidMessages] = useState({})
	const [showCodeInput, setShowCodeInput] = useState({ view: false, id: null })
	const [discountedPrices, setDiscountedPrices] = useState({})
	const [discountedPrice, setDiscountedPrice] = useState(null)
	const [discountNameApplied, setDiscountNameApplied] = useState(false)
	const [giftCardCode, setGiftCardCode] = useState("")
	const [giftCard, setGiftCard] = useState(null)
	const [isCodeValid, setIsCodeValid] = useState(false)

	const notify = (msgContent) =>
		toast(`${code} is a ${msgContent} and has been applied`)

	useEffect(() => {
		getClientDaycareVisits()
	}, [])

	useEffect(() => {
		if (code) {
			setErrorMsg("")
			setValidMessages({})
		}
	}, [code])

	const getClientDaycareVisits = async () => {
		const allVisits = await axios.get(
			`/auth/api/all_daycareVisits_by_client_id/online/${clientID}`
		)
		setVisits(allVisits)
	}

	// Function to initiate Stripe checkout
	const handlePurchase = async (
		passName,
		passType,
		passValue,
		originalValue,
		numOfVisits
	) => {
		const priceToUse = discountedPrice ? discountedPrice * 100 : passValue

		try {
			const { data } = await axios.post("/api/membership/checkout-session", {
				passName,
				passType,
				passValue: originalValue,
				clientId: clientID,
				city: "milton",
				numOfVisits,
				purchasedAt: priceToUse,
				discountName: discountNameApplied,
			})

			const stripe = await stripePromise
			await stripe.redirectToCheckout({ sessionId: data.sessionId })
		} catch (error) {
			console.error("Error initiating checkout:", error)
			alert("Payment failed. Please try again.")
		}
	}

	const passesViewHandler = (passViewType) => {
		setPassesView(passViewType)
	}

	const validateCodeHandler = async (passId, passValue) => {
		try {
			if (!code) {
				setErrorMsg("Enter your code!")
				return
			}

			const response = await axios.get(`/api/discount/${code}`)
			const { pass, msg, type, value, status, name, serviceType, expireDate } =
				response.data
			if (serviceType !== "daycare") {
				return setErrorMsg("Code cannot be used for this item")
			}
			if (moment(expireDate).isAfter(new Date())) {
				return setErrorMsg("Code expired!")
			}

			if (pass && status === "active") {
				const updatedValue =
					type === "amount"
						? passValue - value
						: passValue - (passValue * Number(value)) / 100

				setDiscountedPrices((prev) => ({ ...prev, [passId]: updatedValue }))
				setDiscountedPrice(updatedValue)
				setDiscountNameApplied(name)
				setErrorMsg("")
				setValidMessages((prev) => ({
					...prev,
					[passId]: "Discount applied",
				}))
				setShowCodeInput({ view: false, id: null })
				notify(msg)
			} else {
				setErrorMsg(msg)
			}
		} catch (error) {
			setErrorMsg(
				"An error occurred while validating the code. Please try again."
			)
			console.error(error)
		}
	}

	const validateGiftCardHandler = async () => {
		const response = await axios.post("/api/gift_card/validate", {
			code: giftCardCode,
		})
		const { pass, giftCard } = response.data
		if (pass) {
			setGiftCard(giftCard)
			return true
		} else {
			setGiftCard(null)
			return false
		}
	}

	const renderVisits =
		visits.data && visits.data.length > 0 ? (
			visits.data.map((daycareVisit) =>
				daycareVisit.groomerName === "daycare" ? (
					<div
						className="w-full p-2 border flex flex-col items-center"
						key={daycareVisit.id}
					>
						<p>
							Date (YYYY-MM-DD):{" "}
							{moment(daycareVisit.date).format("YYYY-MM-DD")}
						</p>
						<p className="capitalize">Pet Name: {daycareVisit.nameBreed}</p>
					</div>
				) : null
			)
		) : (
			<div>No visits history available</div>
		)

	return (
		<div className="pb-12 px-4 sm:px-8 md:px-16 max-w-screen-lg mx-auto">
			<ToastContainer
				position="top-right"
				autoClose={2500}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="light"
			/>
			{process.env.REACT_APP_BUY_MODE_LIVE === "yes" &&
				clientLocation === "milton" && (
					<div className="mb-2 flex flex-col sm:flex-row gap-2 justify-start">
						<button
							onClick={() => passesViewHandler("my-purchased-passes")}
							className="bg-slate-600 text-white p-2 rounded-sm sm:w-auto w-full"
						>
							<IoIosCard className="inline mr-2" />
							My Daycare Passes
						</button>
						<button
							onClick={() => passesViewHandler("gift-card-checker")}
							className="bg-slate-600 text-white p-2 rounded-sm sm:w-auto w-full"
						>
							<IoIosCard className="inline mr-2" />
							Gift Cards
						</button>
						<button
							onClick={() => passesViewHandler("buy-passes")}
							className="bg-slate-600 text-white p-2 rounded-sm sm:w-auto w-full"
						>
							<FaCartShopping className="inline mr-2" />
							Buy Daycare Passes
						</button>
					</div>
				)}

			<hr />
			{passesView === "my-purchased-passes" && clientLocation === "milton" ? (
				<div className="flex flex-col md:flex-row gap-4">
					<div className="w-full md:w-2/3">
						<h5>Current Passes/Membership:</h5>
						<div className="flex flex-wrap gap-4">
							{data && data.Memberships.length ? (
								data.Memberships.slice()
									.sort((a, b) => moment(b.createdDate) - moment(a.createdDate))
									.map((pass) => {
										return (
											<div
												className={`flex-1 rounded-sm p-4 mt-4 ${
													pass.numOfVisitsLeft === 0 ||
													moment(pass.expireDate).isBefore(moment())
														? "border-4 border-red-500"
														: "border-4 border-green-500"
												}`}
												key={pass.id}
												style={{ minWidth: "200px" }}
											>
												<h6>
													Status:{" "}
													{pass.numOfVisitsLeft === 0
														? NO_VISITS_LEFT
														: moment().isBefore(pass.expireDate)
														? ACTIVE
														: PASS_EXPIRED}{" "}
												</h6>
												<h6>
													{pass.numOfVisitsLeft > 0 &&
														`Expires on: ${moment(pass.expireDate).format(
															"YYYY-MM-DD"
														)}`}
												</h6>
												<hr />
												<p className="capitalize">Pass Name: {pass.name}</p>
												{pass.discountName && (
													<p className="capitalize">
														Discount name: {pass.discountName}
													</p>
												)}
												<p className="capitalize">
													Orig. Value: ${pass.value.toFixed(2)}
												</p>
												<p className="capitalize">
													Paid: $
													{pass.purchasedAt
														? pass.purchasedAt.toFixed(2)
														: pass.value.toFixed(2)}{" "}
													(No tax)
												</p>
												<p className="capitalize">
													Purchase Source: {pass.purchaseSource}
												</p>
												<p className="capitalize">
													Original Number of visits: {pass.originalNumOfVisits}
												</p>
												<p className="capitalize">
													Number of visits left:{" "}
													<span>{pass.numOfVisitsLeft}</span>
												</p>
												<p className="capitalize">
													Purchase date:{" "}
													{moment(pass.createdDate).format("YYYY-MM-DD")}
												</p>
												<p
													title={pass.orderNumber}
													className="capitalize truncate"
												>
													Order Number: {pass.orderNumber}
												</p>
											</div>
										)
									})
							) : (
								<div>No Passes or Memberships have been purchased yet</div>
							)}
						</div>
					</div>
					<div className="w-full md:w-1/3 border overflow-y-auto">
						<h5 className="pl-2 text-center pt-3">
							<CiBaseball className="inline mr-2" />
							Daycare Visits History
							<PiBoneDuotone className="inline ml-2" />
						</h5>
						<hr />
						<div className="flex flex-col gap-2 mt-4 p-2 overflow-y-auto">
							{renderVisits}
						</div>
					</div>
				</div>
			) : passesView === "gift-card-checker" ? (
				<>
					<h6 className="text-center my-4">
						If you have purchased a gift card, you can check your balance below
						by entering your gift card code
					</h6>
					<GiftCardValidator
						validateGiftCard={validateGiftCardHandler}
						isClient
						setGiftCardCode={setGiftCardCode}
						giftCard={giftCard}
						giftCardCode={giftCardCode}
						setIsCodeValid={setIsCodeValid}
					/>
				</>
			) : (
				<div className="flex flex-wrap border gap-4 justify-center">
					{process.env.REACT_APP_BUY_MODE_LIVE === "yes" &&
					clientLocation === "milton" ? (
						<>
							{/* Half Day Passes */}
							<div className="w-full">
								<h4 className="text-center text-lg font-semibold mb-4 mt-4">
									Half Day Passes
								</h4>
								<div className="flex flex-wrap gap-4 justify-center">
									{daycarePassOptions
										.filter((pass) => pass.type === "HD")
										.map((pass) => (
											<div
												key={pass.id}
												className="card w-full sm:w-[30%] md:w-[22%] text-center p-4 border"
											>
												<h6>{pass.value}</h6>
												<img
													className="w-full md:w-[70%] mx-auto"
													src={pass.img_src}
													alt={pass.value}
												/>
												<button
													onClick={() =>
														setShowCodeInput({
															view:
																pass.id === showCodeInput.id
																	? !showCodeInput.view
																	: true,
															id: pass.id,
														})
													}
													className="mt-2 bg-black text-white rounded-sm"
												>
													{showCodeInput.view && pass.id === showCodeInput.id
														? "Hide code"
														: "I have a code"}
												</button>
												{showCodeInput.view && pass.id === showCodeInput.id && (
													<div className="border mt-2 rounded-sm">
														<span className="text-center text-red-500">
															{errorMsg}
														</span>
														<input
															className="border w-full text-center"
															placeholder="Enter discount code"
															type="text"
															onChange={(e) => setCode(e.target.value)}
														/>
														<button
															onClick={() =>
																validateCodeHandler(pass.id, pass.cost)
															}
															className="bg-yellow-300 w-full"
														>
															Validate Code
														</button>
													</div>
												)}
												<span className="text-center text-green-500 mt-2">
													{validMessages[pass.id]}
												</span>
												<button
													onClick={() =>
														handlePurchase(
															pass.value,
															"pass",
															discountedPrices[pass.id]
																? discountedPrices[pass.id] * 100
																: pass.cost * 100,
															pass.cost * 100,
															pass.numOfVisits
														)
													}
													className="mt-3 bg-blue-700 text-white rounded-sm"
												>
													Buy now for $
													{discountedPrices[pass.id]
														? discountedPrices[pass.id].toFixed(2)
														: pass.cost.toFixed(2)}
												</button>
											</div>
										))}
								</div>
							</div>

							{/* Full Day Passes */}
							<div className="w-full mt-8">
								<h4 className="text-center text-lg font-semibold mb-4">
									Full Day Passes
								</h4>
								<div className="flex flex-wrap gap-4 justify-center">
									{daycarePassOptions
										.filter((pass) => pass.type === "FD")
										.map((pass) => (
											<div
												key={pass.id}
												className="card w-full sm:w-[30%] md:w-[22%] text-center p-4 border"
											>
												<h6>{pass.value}</h6>
												<img
													className="w-full md:w-[70%] mx-auto"
													src={pass.img_src}
													alt={pass.value}
												/>
												<button
													onClick={() =>
														setShowCodeInput({
															view:
																pass.id === showCodeInput.id
																	? !showCodeInput.view
																	: true,
															id: pass.id,
														})
													}
													className="mt-2 bg-black text-white rounded-sm"
												>
													{showCodeInput.view && pass.id === showCodeInput.id
														? "Hide code"
														: "I have a code"}
												</button>
												{showCodeInput.view && pass.id === showCodeInput.id && (
													<div className="border mt-2 rounded-sm">
														<span className="text-center text-red-500">
															{errorMsg}
														</span>
														<input
															className="border w-full text-center"
															placeholder="Enter discount code"
															type="text"
															onChange={(e) => setCode(e.target.value)}
														/>
														<button
															onClick={() =>
																validateCodeHandler(pass.id, pass.cost)
															}
															className="bg-yellow-300 w-full"
														>
															Validate Code
														</button>
													</div>
												)}
												<span className="text-center text-green-500 mt-2">
													{validMessages[pass.id]}
												</span>
												<button
													onClick={() =>
														handlePurchase(
															pass.value,
															"pass",
															discountedPrices[pass.id]
																? discountedPrices[pass.id] * 100
																: pass.cost * 100,
															pass.cost * 100,
															pass.numOfVisits
														)
													}
													className="mt-3 bg-blue-700 text-white rounded-sm"
												>
													Buy now for $
													{discountedPrices[pass.id]
														? discountedPrices[pass.id].toFixed(2)
														: pass.cost.toFixed(2)}
												</button>
											</div>
										))}
								</div>
							</div>
						</>
					) : (
						<div>
							<h5 className="text-center py-2 mt-2">
								Currently available for our Milton Location only
							</h5>
							<div className="border p-4">
								<h4>Half Day Passes</h4>
								<div className="flex flex-row flex-wrap justify-between">
									<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
										<h5>5 Half Day Visits</h5>
										<img
											className="w-full md:w-[70%] mx-auto"
											src="/images/passes/daycare_5_HD.png"
											alt="half day 5 day pass"
										/>
									</div>
									<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
										<h5>10 Half Day Visits</h5>
										<img
											className="w-full md:w-[70%] mx-auto"
											src="/images/passes/daycare_10_HD.png"
											alt="half day 10 day pass"
										/>
									</div>
									<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
										<h5>20 Half Day Visits</h5>
										<img
											className="w-full md:w-[70%] mx-auto"
											src="/images/passes/daycare_20_HD.png"
											alt="half day 20 day pass"
										/>
									</div>
								</div>
							</div>

							<div className="border p-4 mt-4">
								<h4>Full Day Passes</h4>
								<div className="flex flex-row flex-wrap gap-2 justify-between">
									<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
										<h5>5 Full Day Visits</h5>
										<img
											className="w-full md:w-[70%] mx-auto"
											src="/images/passes/5-day-FD.png"
											alt="Full day 5 day pass"
										/>
									</div>
									<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
										<h5>10 Full Day Visits</h5>
										<img
											className="w-full md:w-[70%] mx-auto"
											src="/images/passes/10-day-FD.png"
											alt="Full day 10 day pass"
										/>
									</div>
									<div className="card w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4 mx-4 text-center p-4">
										<h5>20 Full Day Visits</h5>
										<img
											className="w-full md:w-[70%] mx-auto"
											src="/images/passes/20-day-FD.png"
											alt="Full day 20 day pass"
										/>
									</div>
								</div>
							</div>
						</div>
					)}
				</div>
			)}
		</div>
	)
}
