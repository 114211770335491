import React, { useRef } from "react"
import { gsap } from "gsap"
import { useLayoutEffect } from "react"
import { FaInfoCircle } from "react-icons/fa"
import { Tooltip as ReactTooltip } from "react-tooltip"
import TooltipPortal from "../TooltipPortal"

const BookingCard = ({ extraImg, image, title, description, onClick }) => {
	const tl = useRef(null)
	const container = useRef(null)
	const tooltipId = `tooltip-${title.replace(/\s+/g, "-").toLowerCase()}`

	useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			tl.current = gsap.timeline({ paused: true })
			tl.current.to(container.current, {
				scale: 1.03,
				borderColor: "#65BFF8",
				backgroundColor: "#DDF2FF",
				boxShadow: "2px 4px 12px 0px rgba(0, 156, 255, 0.10)",
				duration: 0.2,
			})
		}, container)

		return () => ctx.revert()
	}, [])

	return (
		<div
			className="p-phone w-full rounded-default border border-lightGray flex flex-col sm:flex-row flex-wrap items-center justify-start cursor-pointer gap-4 sm:gap-3 md:gap-5"
			style={{ backgroundColor: "#FFFFFF" }}
			onClick={onClick}
			onMouseEnter={() => tl.current.play()}
			onMouseLeave={() => tl.current.reverse()}
			ref={container}
		>
			<div className="flex lg:flex-col">
				{/* Profile Image (Extra) */}
				{extraImg && (
					<div className="flex items-center justify-center imageCard relative overflow-hidden w-[50px] sm:w-[60px] md:w-[70px] min-w-[50px] h-[50px] sm:h-[60px] md:h-[70px] rounded-full border-[6px] border-orange-500">
						<img
							src={extraImg}
							alt={title}
							className="w-full h-full object-cover rounded-full"
						/>
					</div>
				)}

				{/* Main Image */}
				<div className="flex items-center justify-center imageCard relative overflow-hidden w-[50px] sm:w-[60px] md:w-[70px] min-w-[50px] h-[50px] sm:h-[60px] md:h-[70px] rounded-full border border-lightGray">
					<img
						style={{
							zIndex: 999,
						}}
						src={image}
						alt={title}
						className="w-full h-full object-cover rounded-full"
					/>
				</div>
			</div>

			{/* Text & Icons */}
			<div className="flex-1 min-w-0 flex flex-col w-full text-center">
				<h5 className="mb-2 text-lightGray font-medium textCard flex flex-wrap items-center justify-center sm:justify-start gap-1 sm:gap-2 md:gap-3">
					{extraImg && (
						<span className="flex items-center flex-shrink-0">
							<FaInfoCircle
								data-tooltip-id={tooltipId}
								color="blue"
								className="flex-shrink-0"
							/>
							<TooltipPortal>
								<ReactTooltip
									id={tooltipId}
									place="top-end"
									content="Express is a special service with an additional cost, tailored to your dog's behavior. A dedicated groomer will work exclusively on your pet, ensuring a faster grooming experience—typically completed in 1 to 2 hours, compared to the standard 2 to 4 hours."
									style={{
										maxWidth: "30vw",
									}}
									className="tooltip"
									delayShow={400}
									delayHide={200}
									variant="info"
								/>
							</TooltipPortal>
						</span>
					)}
					{title}
					{(title === "Grooming Express" || title === "Bath Express") && (
						<span className="text-md text-cyan-700 bg-yellow-200 shadow-md shadow-slate-500 rounded-xl p-1 font-mono">
							<b>+</b>$15
						</span>
					)}
				</h5>
				<p className="mb-0 text-lightGray textCard sm:text-center md:text-left">
					{description}
				</p>
			</div>
		</div>
	)
}

export default BookingCard
