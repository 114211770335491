import React, { useState, useEffect } from "react"
import moment from "moment"
import "./style.scss"
import axios from "axios"
import Select from "react-select"
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Input,
	Label,
} from "reactstrap"
import optionsAdmin from "../../components/Calendars/CalendarOptions/servicePaolaOptions"
import optionsNoSchedule from "../../components/Calendars/CalendarOptions/serviceOptionsNoSchedule"
import options from "../../components/Calendars/CalendarOptions/serviceOptions"
import LoadPage from "../../components/LoadingPage"

const userGroomers = [
	// "groomer1",
	"groomer2",
	"groomer3",
	"groomer4",
	"groomer5",
	"groomer6",
]

const jobType = localStorage.getItem("JOBTYPE")

const ModalSearchAll = (props) => {
	const [searchResults, setSearchResults] = useState([])
	const [showEventEdit, setShowEventEdit] = useState(false)
	const [appointment, setAppointment] = useState("")
	const [slotEvent, setSlotEvent] = useState("")
	const [title, setTitle] = useState("")
	const [titleInputEmpty, setTitleInputEmpty] = useState(false)
	const [breedName, setBreedName] = useState("")
	const [breedInputEmpty, setBreedInputEmpty] = useState(false)
	const [lastModifiedBy, setLastModifiedBy] = useState(
		localStorage.getItem("USERNAME")
	)
	const [start, setStart] = useState("")
	const [end, setEnd] = useState("")
	const [appointmentModifiers, setAppointmentModifiers] = useState([])
	const [eventHistoryToggle, setEventHistoryToggle] = useState(false)
	const [loadingAxiosReq, setLoadingAxiosReq] = useState(false)

	useEffect(() => {
		setSearchResults(props.searchResultsDetails)
	}, [props.searchResultsDetails])

	useEffect(() => {
		if (!props.modalSearchResults) {
			setShowEventEdit(false)
		}
	}, [props.modalSearchResults])

	const showEventEditHandler = async (id) => {
		handleEventGetter(id)
	}

	const handleEventGetter = async (id) => {
		await axios
			.get(`/schedule/calendar/one/${id}`)
			.then((res) => {
				if (userGroomers.includes(jobType)) {
					if (res.data.breedName) {
						setTitle(res.data.breedName)
					}
					setTitle(res.data.title)
				} else {
					setTitle(res.data.title)
				}
				setSlotEvent(res.data)
				setAppointment(res.data.appointment)
				setBreedName(res.data.breedName)
				setStart(res.data.start)
				setEnd(res.data.end)
				setLastModifiedBy(res.data.lastModifiedBy)
				setAppointmentModifiers(res.data.AppointmentModifiers)
				setShowEventEdit(true)
			})
			.catch((error) => console.log(error))
	}

	const handleSearchAppointmentsSubmit = async () => {
		await axios
			.get(`/schedule/calendar/search/all/${props.searchAppointmentField}`, {
				title: props.searchAppointmentField,
			})
			.then((res) => {
				props.setTriggerFetchEvents(true)
				const filteredEvents = res.data.filter(
					(event) =>
						event.city === "milton" && !event.deletedAt && !event.isBeingBooked
				)
				setSearchResults(filteredEvents)
				setShowEventEdit(false)
			})
			.catch((err) => console.log(err))
	}

	const onSubmitEventToEdit = async (e) => {
		e.preventDefault()

		let obj = {
			id: slotEvent.id,
			title: title
				? title.toLowerCase()
				: appointment.value === "schedule" || appointment.value === "staffOnly"
				? ""
				: null,
			breedName: breedName
				? breedName.toLowerCase()
				: appointment.value === "schedule" || appointment.value === "staffOnly"
				? ""
				: null,
			start: start,
			end: end,
			appointment: appointment.value,
			lastModifiedBy: localStorage.getItem("USERNAME"),
			isBeingBooked: false,
		}
		if (
			appointment === "online" ||
			appointment === "onlineBath" ||
			appointment === "onlineCat" ||
			appointment === "staffOnly" ||
			appointment.value === "online" ||
			appointment.value === "onlineBath" ||
			appointment.value === "onlineCat" ||
			appointment.value === "staffOnly"
		) {
			//ONLINE title and breed-name events must be empty
			if (title || breedName) {
				if (title.length > 0 || breedName.length > 0) {
					if (title) {
						setTitleInputEmpty(true)
					}
					if (breedName) {
						setBreedInputEmpty(true)
					}
					return alert(
						"Action is not Allowed! Online appointments must be totally empty!"
					)
				}
			}
		} else if (appointment !== "schedule") {
			if (
				(typeof appointment === "object" && appointment.value !== "schedule") ||
				appointment !== "schedule" ||
				appointment !== "staffOnly" ||
				appointment.value !== "staffOnly"
			) {
				if (!title || !breedName) {
					if (!title) {
						setTitleInputEmpty(true)
					}
					if (!breedName) {
						setBreedInputEmpty(true)
					}
					return alert("Missing required fields!")
				}
			}
		}

		setLoadingAxiosReq(true)
		await axios
			.put(`/schedule/calendar/update/${slotEvent.id}`, obj)
			.then(() => {
				setLoadingAxiosReq(false)
				setAppointment("")
				setTitle("")
				setBreedName("")
				handleSearchAppointmentsSubmit()
				props.setTriggerFetchEvents(false)
			})
			.catch((error) => {
				console.log(error)
				setLoadingAxiosReq(false)
			})
	}

	const handleDeleteEvent = async (id) => {
		if (
			window.confirm(`Are you sure you wish to delete this Event permanently?`)
		) {
			setLoadingAxiosReq(true)

			if (title) {
				// console.log("To soft delete");
				const clientDeletedBy = {
					deletedAt: moment(new Date()).format("YYYY/MM/DD hh:mm"),
					deletedBy: localStorage.getItem("USERNAME"),
					isBeingBooked: false,
				}
				await axios
					.put(`/schedule/event/soft_delete/${id}`, clientDeletedBy)
					.then(async () => {
						handleSearchAppointmentsSubmit()
						setLoadingAxiosReq(false)
						setAppointment("")
						props.setTriggerFetchEvents(false)
					})
					.catch((error) => {
						console.log(error)
						setLoadingAxiosReq(false)
					})
			} else if (id) {
				// console.log("To delete permanently");
				await axios
					.delete(`/schedule/calendar/delete/${id}`)
					.then(() => {
						handleSearchAppointmentsSubmit()
						setLoadingAxiosReq(false)
						props.setTriggerFetchEvents(false)
					})
					.catch((error) => {
						console.log(error)
						setLoadingAxiosReq(false)
					})
			}
		}
	}

	const toggleHistoryHandler = (e) => {
		e.preventDefault()
		if (!appointmentModifiers.length) {
			return
		}
		return setEventHistoryToggle(!eventHistoryToggle)
	}

	return (
		<Modal
			modalClassName="modal-cambridge-calendar"
			size="m"
			isOpen={props.modalSearchResults}
			toggle={props.toggleModalSearchResults}
		>
			<ModalHeader toggle={props.toggleModalSearchResults}>
				All Calendars Search Results for: <b>{props.searchAppointmentField}</b>
			</ModalHeader>
			<ModalBody>
				{searchResults.length ? (
					!showEventEdit ? (
						searchResults.map((results) => {
							return (
								<div
									className={
										results.name === "paola"
											? "search-results search-results__paola"
											: results.name === "claudia"
											? "search-results search-results__claudia"
											: results.name === "diana"
											? "search-results search-results__diana"
											: results.name === "frank"
											? "search-results search-results__frank"
											: results.name === "tristen"
											? "search-results search-results__tristen"
											: "search-results search-results__any"
									}
									key={results.id}
								>
									<p className="search-results_details text-center">
										<i className="fas fa-cut mr-2"></i>
										<strong>{results.name.toUpperCase()}</strong>
									</p>
									<p className="search-results_details">
										<i className="fas fa-paw mr-2"></i> Description{" "}
										<i className="fas fa-long-arrow-alt-right mr-2"></i>
										<strong>
											{results.title}{" "}
											{results.breedName && results.breedName.toUpperCase()}
										</strong>
										<br />
										<span>
											Event Code: <strong>{results.appointment}</strong>
										</span>
									</p>
									<p className="search-results_details">
										<i className="fas fa-clock mr-2"></i> Date{" "}
										<i className="fas fa-long-arrow-alt-right mr-3"></i>
										<strong>
											{moment(results.start).format("YYYY-MM-DD hh:mm a")}
										</strong>
									</p>
									<button
										onClick={() => showEventEditHandler(results.id)}
										className="btn-block btn btn-info"
									>
										Edit
									</button>
								</div>
							)
						})
					) : (
						<>
							<button
								onClick={() => setShowEventEdit(false)}
								className="btn btn-warning btn-block mb-2"
							>
								Back to search results
							</button>
							<Form onSubmit={onSubmitEventToEdit}>
								<FormGroup>
									<Label>
										Current App type code:{" "}
										<b>
											{typeof appointment === "object"
												? appointment.value
												: appointment}
										</b>{" "}
									</Label>
									<Select
										name="form-field-name"
										value={appointment}
										options={
											props.groomer === "paola"
												? optionsAdmin
												: slotEvent.appointment !== "schedule" ||
												  appointment !== "schedule"
												? optionsNoSchedule
												: options
										}
										placeholder="Select one of the following:"
										isSearchable={false}
										onChange={(value) => {
											console.log(value)
											return setAppointment(value)
										}}
									/>
									<br />
									{userGroomers.includes(jobType) ? (
										slotEvent.appointment === "online" ||
										slotEvent.appointment === "onlineBath" ||
										slotEvent.appointment === "onlineCat" ||
										slotEvent.appointment === "schedule" ? (
											appointment === "schedule" ||
											appointment.value === "schedule" ? (
												<>
													<Label>Custom Staff Event</Label>
													<Input
														type="text"
														name="title"
														id="title"
														defaultValue={slotEvent.title}
														placeholder="(Optional) Type your details or leave it empty"
														onChange={(e) => {
															setTitle(e.target.value)
															if (e.target.value) {
																setTitleInputEmpty(false)
															}
														}}
														onKeyUp={(e) => {
															if (e.keyCode === 8) {
																setTitle(e.target.value)
															}
														}}
														className={titleInputEmpty ? "borderError" : ""}
													/>
													<br />
												</>
											) : (
												// Employee Profile Edit online event STARTS
												<>
													<Label>* Phone and other details</Label>
													<Input
														type="text"
														name="title"
														id="title"
														defaultValue={slotEvent.title}
														placeholder="Enter Phone and other details"
														onChange={(e) => {
															setTitle(e.target.value)
															if (e.target.value) {
																setTitleInputEmpty(false)
															}
														}}
														onKeyUp={(e) => {
															if (e.keyCode === 8) {
																setTitle(e.target.value)
															}
														}}
														className={titleInputEmpty ? "borderError" : ""}
													/>
													<br />
													<Label>* Breed and Name</Label>
													<Input
														type="text"
														name="breedName"
														id="breedName"
														defaultValue={slotEvent.breedName}
														placeholder="*Enter Breed and Name"
														onKeyUp={(e) => {
															if (e.keyCode === 8) {
																setBreedName(e.target.value)
															}
														}}
														onChange={(e) => {
															setBreedName(e.target.value)
															if (e.target.value) {
																setBreedInputEmpty(false)
															}
														}}
														className={breedInputEmpty ? "borderError" : ""}
													/>
													{/* Employee Edit online event ENDS */}
												</>
											)
										) : (
											<>
												{(typeof appointment !== "object" &&
													appointment !== "schedule") ||
												appointment.value !== "schedule" ? (
													<>
														<p>
															{slotEvent.breedName
																? "Breed and Name: "
																: "Event details: "}
															<b style={{ textTransform: "capitalize" }}>
																{slotEvent.breedName
																	? slotEvent.breedName
																	: slotEvent.title}
															</b>
														</p>
														<Input
															type="text"
															name="breedName"
															id="breedName"
															defaultValue={slotEvent.breedName}
															placeholder="*Enter Breed and Name"
															onKeyUp={(e) => {
																if (e.keyCode === 8) {
																	setBreedName(e.target.value)
																}
															}}
															onChange={(e) => {
																setBreedName(e.target.value)
																if (e.target.value) {
																	setBreedInputEmpty(false)
																}
															}}
															className={breedInputEmpty ? "borderError" : ""}
														/>
													</>
												) : (
													<>
														<Label>Custom Staff Event</Label>
														<Input
															type="text"
															name="title"
															id="title"
															defaultValue={slotEvent.title}
															placeholder="(Optional) Type your details or leave it empty"
															onChange={(e) => {
																setTitle(e.target.value)
																if (e.target.value) {
																	setTitleInputEmpty(false)
																}
															}}
															onKeyUp={(e) => {
																if (e.keyCode === 8) {
																	setTitle(e.target.value)
																}
															}}
															className={titleInputEmpty ? "borderError" : ""}
														/>
														<br />
													</>
												)}
											</>
										)
									) : appointment === "schedule" ||
									  appointment.value === "schedule" ? (
										// Admin and receptionist can Edit Red events STARTS
										<>
											<Label>Custom Staff Event</Label>
											<Input
												type="text"
												name="title"
												id="title"
												defaultValue={slotEvent.title}
												placeholder="(Optional) Type your details or leave it empty"
												onChange={(e) => {
													setTitle(e.target.value)
													if (e.target.value) {
														setTitleInputEmpty(false)
													}
												}}
												onKeyUp={(e) => {
													if (e.keyCode === 8) {
														setTitle(e.target.value)
													}
												}}
												className={titleInputEmpty ? "borderError" : ""}
											/>
											<br />
										</>
									) : (
										// Admin and receptionist can Edit events STARTS
										<>
											<Label>* Phone and other details</Label>
											<Input
												type="text"
												name="title"
												id="title"
												defaultValue={slotEvent.title}
												placeholder="Enter Phone and other details"
												onChange={(e) => {
													setTitle(e.target.value)
													if (e.target.value) {
														setTitleInputEmpty(false)
													}
												}}
												onKeyUp={(e) => {
													if (e.keyCode === 8) {
														setTitle(e.target.value)
													}
												}}
												className={titleInputEmpty ? "borderError" : ""}
											/>
											<br />
											<Label>* Breed and Name</Label>
											<Input
												type="text"
												name="breedName"
												id="breedName"
												defaultValue={slotEvent.breedName}
												placeholder="* Enter Breed and Name"
												onKeyUp={(e) => {
													if (e.keyCode === 8) {
														setBreedName(e.target.value)
													}
												}}
												onChange={(e) => {
													setBreedName(e.target.value)
													if (e.target.value) {
														setBreedInputEmpty(false)
													}
												}}
												className={breedInputEmpty ? "borderError" : ""}
											/>
										</>
										// Admin and receptionist ENDS
									)}

									<p className="time-on-modal text-left">
										Appointment:{" "}
										<b>{moment(start).format("dddd, MMMM Do YYYY, h:mm a")}</b>
									</p>
									<p className="text-left">
										Last change made by: <strong>{lastModifiedBy}</strong>
									</p>
									<button
										onClick={toggleHistoryHandler}
										className="btn btn-dark btn-block"
									>
										{eventHistoryToggle ? "Hide" : "Show"} Change history
									</button>
									{eventHistoryToggle
										? appointmentModifiers.length
											? appointmentModifiers.map((value) => {
													return (
														<p key={value.id} className="event-history-wrapper">
															<i className="fas fa-user-edit"></i> {value.name}{" "}
															<i className="fas fa-pencil-alt"></i> {value.date}
														</p>
													)
											  })
											: null
										: null}
									{loadingAxiosReq ? (
										<LoadPage />
									) : (
										<Button color="info" style={{ marginTop: "1rem" }} block>
											Submit Event
										</Button>
									)}
								</FormGroup>
							</Form>
							<Button
								onClick={() => {
									handleDeleteEvent(slotEvent.id)
								}}
								color="danger"
								style={{ marginTop: "1rem" }}
								block
							>
								Delete Event
							</Button>
						</>
					)
				) : null}
			</ModalBody>
		</Modal>
	)
}

export default ModalSearchAll
