import { useEffect, useRef } from "react"
import axios from "axios"
import { jwtDecode } from "jwt-decode"

const useSessionChecker = (username) => {
	const intervalRef = useRef(null)
	const accessString = localStorage.getItem("JWT")

	useEffect(() => {
		if (!username || !accessString) {
			return
		}
		try {
			const decodedToken = jwtDecode(accessString)
			if (decodedToken.exp * 1000 < Date.now()) {
				localStorage.clear()
				alert("You've been signed out! Please login again")
				window.location.href = "/auth/login"
				return
			}
		} catch (error) {
			console.error("Error decoding token:", error)
			localStorage.clear()
			alert("You've been signed out! Please login again")
			window.location.href = "/auth/login"
			return
		}

		const checkSessionStatus = async () => {
			try {
				const response = await axios.get(`/auth/check_session/${username}`, {
					headers: { jwt: `${accessString}` },
				})
				if (response.status === 200) {
					// Do nothing
					console.log("Checking session")
				}
			} catch (error) {
				if (error.response && error.response.status === 403) {
					console.log("Session not allowed. Logging out...")
					localStorage.clear()
					alert("You've been signed out! Please login again")
					window.location.href = "/auth/login"
				} else {
					console.error("Error decoding token:", error)
					localStorage.clear()
					alert("You've been signed out! Please login again")
					window.location.href = "/auth/login"
				}
			}
		}

		checkSessionStatus()
		const POLLING_INTERVAL = 300000 // 5 minutes in ms
		intervalRef.current = setInterval(() => {
			checkSessionStatus()
		}, POLLING_INTERVAL)

		return () => clearInterval(intervalRef.current)
	}, [username])

	return null
}

export default useSessionChecker
